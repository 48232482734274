import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import img from "../assets/images/404.jpg"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

const NotFoundPage = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata.title

  return (
    // <Layout location={location} title={siteTitle}>
    <Layout>
      <SEO title="404: Not Found" />
      <Navbar />
      {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
      <div className="container mt-12">
      <h1>404: Not Found</h1>
        <img src={img} alt="404 Page Image" />
      </div>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
